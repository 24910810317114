.mainview{
    padding: 20px;
    background-color: #f0cece;
}
.locationview h1{
    font-size: 22px;
    display: flex;
    align-items: center;
}
.locationview p{
    display: flex;
    align-items: center;
    color: rgb(82, 78, 78);
}
.Departurebox p{
    font-weight: bold;
    margin: 5px;
    font-size: 18px;
}
.Departurebox select{
    width: 150px;
}
.STARTINGFROMdiv p{
    margin: 4px;
    font-size: 13px;
}
.STARTINGFROMdiv h1{
    margin: 4px;
    font-size: 26px;
    font-weight: bold;
}

.buttonsss .btnn1{
    border: 3px solid brown;
    text-transform: capitalize;
    background-color: white;
    color: black;
    height: 40px;
    margin: auto;
    padding: 8px 30px;
}
.buttonsss .btnn2{
    border: 3px solid brown;
    text-transform: capitalize;
    background-color: brown;
    color: white;
    height: 40px;
    margin: auto 10px;
    padding: 8px 30px;

}

.PackageIncludes{
    padding: 20px;
    margin: 50px;
}
.PackageIncludes p{
    margin: 10px 0;
    text-align: justify;
    letter-spacing: 0.6px;
}
.PackageIncludes h6{
    margin: 4px 0;
    letter-spacing: 1px;
    font-size: 19px;
    font-weight: bold;
}

.PackageIncludes .PriceIncludes{
    margin: 30px 0;
    text-align: justify;
    font-size: 18px;
    letter-spacing: 1px;
}


.PackageTours_div {
    background-color: #ecccc4;
    padding: 40px 5px;
    text-align: center;
}

.PackageTours_div h3{
    text-align: start;
    margin:10px 40px;
    border-bottom: 2px solid gray;
}

/* .PackageTours_div .booknowbtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
} */


.Packagetour_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.detailtour_packages {
    margin: 20px;
    width: 500px;
    text-align: justify;
    border-radius: 6px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.detailtour_packages img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
}

.detailtour_packages h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
    /* width: 290px; */
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.detailtour_packages p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: start;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis; */

}

.LunchDinner{
    display: flex;
    justify-content: space-between;
    padding: 40px 50px 40px 20px;
}
.divdinner_lunch{
    display: flex;
    align-items: center;
}
.divdinner_lunch div img{
    width: 50px;
    height: 50px;
}
.divdinner_lunch div p{
    margin: 2px;
    font-size: 13px;
}


.about_info_text p{
    letter-spacing: 1px;
}
.about_info_text div{
    display: flex;
    align-items: center;
}
.showHidebtn1{
    background-color: #2c1619;
    color: white;
    border: 1px solid #2c1619;
    letter-spacing: 1px;
    border-radius: 3px;
    font-size: 16px;
    padding: 12px 20px;
    margin: 25px 20px 25px 0;
    display: flex;
    align-items: center;
}
.showHidebtn2{
    background-color: brown;
    color: white;
    border: 1px solid brown;
    letter-spacing: 1px;
    border-radius: 3px;
    font-size: 16px;
    padding: 12px 20px;
    margin: 25px 20px 25px 0;
    display: flex;
    align-items: center;
}

@media screen and (max-width:767px) {
    .PackageIncludes{
        padding: 10px;
        margin: 50px 10px;
    }
  
}
