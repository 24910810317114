.athospital_welcomeDiv {
    text-align: center;
    padding: 20px 0;
    background-color: #e7e7f1;
    font-family: "Noto Sans", sans-serif;
}

.athospitalhome {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}


.athospitalhome .imggdiv {
    padding: 10px 50px;

}

.athospitalhome .imggdiv img {
    border-radius: 6px;
    width: 100%;
    height: 400px;
    object-fit: cover;
    border: 6px solid white;
}

.athospitalhome .textdiv {
    padding: 10px 50px;
    text-align: start;
}

.athospitalhome .textdiv h6 {
    font-size: 36px;
    font-weight: bold;
    /* border-bottom: 1px solid lightgray; */
    color: #fe5955;
    letter-spacing: 1px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

.athospitalhome .textdiv p {
    font-size: 17px;
    margin: 0px;
    letter-spacing: 1px;
    color: rgb(48, 47, 47);
    padding-top: 20px;
    text-align: justify;

}

.athospitalhome .textdiv button {
    background: linear-gradient(to right, #ff5f5f, #fe977b);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}

.athospitalhome .textdiv button a {
    text-decoration: none;
    color: white;

}

.allpackages_div {
    background-color: #ecc4e4;
    padding: 40px 5px;
    text-align: center;
}

.packages_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.packages_slide a {
    text-decoration: none;
}

.packages_life_Slider {
    margin: 20px;
    width: 350px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 4px;
    background-color: white;
    padding-bottom: 10px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.packages_life_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    /* border-radius: 4px 4px 0 0; */
}

.packages_life_Slider h4 {
    margin: 20px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
    width: auto;
    text-align: center;
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.allpackages_div .explorebtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.ConsultNow div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.ConsultNow div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #e7e7f1;
    color: black;
}

.ConsultNow div h2 {
    font-size: 24px;
    padding: 10px 15%;
    font-weight: bold;
}

.ConsultNow .ConsultNowbtn {
    background: linear-gradient(to right, #1f1832, #2b122e);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
}

.PopularTours_div {
    background-color: #c4eccb;
    padding: 40px 5px;
    text-align: center;
}

.PopularTours_div .booknowbtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
    border-radius: 0 0 8px 8px;
}

.PopularTours_div .butnn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
}

.tour_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.tour_slide a {
    text-decoration: none;
}

.populartour_Slider {
    margin: 20px;
    width: 340px;
    text-align: justify;
    border-radius: 44px 44px 6px 6px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}
.populartour_Slider a{
    text-decoration: none;
    color: black;

}

.populartour_Slider img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px 44px 0 0;
}

.populartour_Slider h4 {
    margin: 10px;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    /* width: 290px; */
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.populartour_Slider p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: start;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 2;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}


.tanishka_oupackagediv {
    margin: 0px 0;
    background-color: #ccc3b0;
    color: black;
    padding: 40px 0;
    text-align: center;

}

.tanishka_oupackagediv .tanishka_popular {
    margin: 40px 3%;

}

.tanishka_oupackagediv .tanishka_popular h2 {
    font-size: 40px;
    text-align: center;
}

.tanishka_oupackagediv button {
    background: linear-gradient(to right, #662326, #501010);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
}

.bbgspeak {
    background-color: #a01d1b;
    color: white;
    padding: 40px 0;
}

.bbgspeak .packagesdivdetail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bbgspeak .imgage {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

.bbgspeak div h1 {
    font-size: 30px;
    font-weight: bold;
}

.bbgspeak div .ourExciting span {
    font-size: 50px;
    font-weight: bold;
    margin: 10px;
}

.bbgspeak button {
    background: linear-gradient(to right, #662326, #501010);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin: 10px;
}

.bbgspeak button:hover {
    background: linear-gradient(to right, #cac5c5, #ffffff);
    color: black;

}

.TravelOptions {
    background-color: #e4ecc4;
    padding: 40px 5px;
    text-align: center;
}

.TravelOptions_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.TravelOptions_slide a {
    text-decoration: none;
}


.TravelOptions_life_Slider {
    margin: 20px;
    width: 340px;
    text-align: justify;
    border: 5px solid white;
    border-radius: 40px;
    background-color: #a72b15;
    color: white;
    padding-bottom: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.TravelOptions_life_Slider img {
    height: 40vh;
    width: 100%;
    object-fit: cover;
    border-radius: 35px 35px 0 0;
}

.TravelOptions_life_Slider h4 {
    margin: 0;
    padding: 10px 0;
    font-weight: 700;
    font-size: 20px;
    width: auto;
    text-align: center;
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.TravelOptions .explorebtn {
    background: linear-gradient(to right, #841628, #501f10);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 40px;
}

.ourprojectgreen {
    background-color: rgb(51, 19, 40) !important;
    padding: 50px 0;
}

.greenbgimg img {
    background-color: #ff554e;
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    padding: 20px;
}

@media screen and (max-width:767px) {
    .athospitalhome .imggdiv {
        padding: 10px 20px;

    }

    .athospitalhome .textdiv {
        padding: 10px 20px;
    }

    .athospitalhome .textdiv h6 {
        font-size: 40px;
    }

    .athospitalhome .textdiv h6 {
        font-size: 30px;
    }

    .packages_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .packages_slide h1 {
        font-size: 18px;
    }

    .packages_life_Slider {
        margin: 20px 0px;
        width: 100%;

    }

    .packages_life_Slider img {
        height: 250px;
        width: 100% !important;
        margin: 0;
    }

    .tour_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .tour_slide h1 {
        font-size: 18px;
    }

    .populartour_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }

    .TravelOptions_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .TravelOptions_slide h1 {
        font-size: 18px;
    }

    .TravelOptions_life_Slider {
        margin: 20px 0px;
        width: 100%;

    }


    .TravelOptions_life_Slider img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }

    .ConsultNow div {
        padding: 30px 20px;
    }

    .ConsultNow div img {
        width: 100%;
        border-radius: 2px;
    }


    .ConsultNow div h2 {
        font-size: 20px;
        padding: 10px 1%;
    }

    .tanishka_oupackagediv .tanishka_popular h2 {
        font-size: 24px;
    }

    .bbgspeak {
        padding: 40px 10px;
        text-align: center;
    }

    .bbgspeak div h1 {
        font-size: 24px;
    }

    .bbgspeak div .ourExciting span {
        font-size: 45px;
    }

    .greenbgimg {
        padding: 20px 0;
    }

    .greenbgimg img {
        width: 120px;
        height: 120px;
    }


}