
.Hos_Domestic_topdiv {
    margin-bottom: 13%;
}

.Hos_Domestic_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Hos_Domestic_topdiv .hosp_Domestichead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Hos_Domestic_topdiv .hosp_Domestichead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}



@media screen and (max-width:767px) {
    .Hos_Domestic_topdiv {
        margin-bottom: 20%;
    }
    
    .Hos_Domestic_topdiv img {
        height: 250px;
    }

    .Hos_Domestic_topdiv .hosp_Domestichead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .Hos_Domestic_topdiv .hosp_Domestichead h1 {
        font-size: 30px;
    }

}