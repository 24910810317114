
.Aboutus_topdiv {
    margin-bottom: 13%;
}

.Aboutus_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Aboutus_topdiv .hosp_aboutushead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Aboutus_topdiv .hosp_aboutushead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}



@media screen and (max-width:767px) {
    .Aboutus_topdiv {
        margin-bottom: 23%;
    }
    
    .Aboutus_topdiv img {
        height: 250px;
    }

    .Aboutus_topdiv .hosp_aboutushead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .Aboutus_topdiv .hosp_aboutushead h1 {
        font-size: 30px;
    }

}