.Travelogue_topdiv {
    margin-bottom: 13%;
}

.Travelogue_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Travelogue_topdiv .traveldiv_head {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Travelogue_topdiv .traveldiv_head h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}


.Travelogue_div {
    background-color: #ecd8c4;
    padding: 40px 5px;
    text-align: center;
}

/* .Travelogue_div .butnn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
} */

.travelogue_slide {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.travelogue_slide a {
    text-decoration: none;
}

.travelogue_slidedetail {
    margin: 20px;
    width: 280px;
    text-align: justify;
    border-radius: 44px;
    background-color: white;
    padding-bottom: 0px;
    color: black;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.travelogue_slidedetail img {
    height: 40vh;
    border: 5px solid white;
    width: 100%;
    object-fit: cover;
    border-radius: 44px;
}

.travelogue_slidedetail h4 {
    margin: 10px 20px 0 20px;
    font-weight: 700;
    font-size: 18px;
    /* width: 290px; */
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}

.travelogue_slidedetail h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 20px 0 20px;

}

.travelogue_slidedetail p {
    margin: 10px 20px;
    font-size: 15px;
    letter-spacing: 1px;
    text-align: start;
    display: -webkit-box;
    /* Create a flexible box layout */
    -webkit-line-clamp: 2;
    /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    /* Set the box orientation to vertical */
    overflow: hidden;
    /* Hide the overflowing content */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */

}

.Travelogue_div .booknowbtn {
    background: linear-gradient(to right, #362366, #4a1050);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
    margin-top: 10px;
    border-radius: 44px;
}

@media screen and (max-width:767px) {
    .Travelogue_topdiv img {
        height: 250px;
    }

    .Travelogue_topdiv {
        margin-bottom: 20%;
    }
    .Travelogue_topdiv .traveldiv_head {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .Travelogue_topdiv .traveldiv_head h1 {
        font-size: 30px;
    }

    .travelogue_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .travelogue_slide h1 {
        font-size: 18px;
    }

    .travelogue_slidedetail img {
        height: 250px;
        width: 100% !important;
        /* margin-left: 10%; */
    }

}