.searchdiv{
    width: 700px;
    margin-top: 20px;
}
.searchdiv button{
    background: linear-gradient(to right, #5225c5, #a619b6);
    color: white;
    text-transform: capitalize;
    font-size: 16px;
    padding: 8px 30px;
}


.ClinicalExcellence {
    background-color: #2d5028;
    color: white;
    text-align: center;
    padding: 40px;
    margin-top: -4%;
}

.ClinicalExcellence h1 {
    font-weight: bold;
    font-size: 30px;
    letter-spacing: 1px;
    padding: 30px 0;
}

.ClinicalExcellence p {
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 1px;
}

.Clinical_detail {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;
    width: 280px;
    margin: 20px;
    padding:40px;
    color: white;
    background-color: #459137;
    border-radius: 6px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Clinical_detail div {
    text-align: center;
}

.Clinical_detail div img {
    margin: 10px 0;
    width: 80px;
    border-radius: 6px;
}

.Clinical_detail div p {
    text-shadow: 20px 10px 40px black, 20px 10px 40px black;
    font-size: 15px;

}
.Clinical_detail div .countno{
    background-color: #fff;
    color: green;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 20px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}