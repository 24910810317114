.DetailGallery_ImagesContainer {
    padding: 3% 5%;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    position: relative;
}

.DetailGallery_ImagesContainer::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url('../../../assest/taniska/image_2.jpg');
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.2;
    z-index: -44;
}

.DetailGallery_ImagesContainer .react-photo-gallery--gallery div {
    align-items: center;
    justify-content: center;
    display: flex;
}

.DetailGallery_ImagesContainer .react-photo-gallery--gallery div img {
    object-fit: cover;
    width: 330px;
    height: 270px;
    margin: 5px !important;
}

.DetailGallery_title {
    margin-top: 1%;
    margin-bottom: 4%;
    font-size: 28px;
    font-weight: bold;
    text-transform: uppercase;
}

.DetailGallery_photo_container {
    display: block !important;
    border: 1px solid gray;
    border-radius: 4px;
    margin: 10px;
    transition: all 0.5s;
    overflow: hidden;
}

.DetailGallery_photo_container:hover {
    transform: translate(0, 5px);
    box-shadow: 0 0 20px white, 0 0 20px white;
}


.DetailGallery_photo_container .galleryphoto_DetailGallery {
    object-fit: cover;
    width: 300px;
    height: 200px;
    transition: all 0.5s;

}

.DetailGallery_photo_container .galleryphoto_DetailGallery:hover {
    transform: scaleX(1.2) scaleY(1.1);
    object-fit: cover;
}


@media only screen and (max-width: 767px) {
    .DetailGallery_ImagesContainer {
        padding: 10px;
    }
    .DetailGallery_title {
        font-size: 22px;
        margin-bottom: 8%;
        margin-top: 4%;
    }

    .DetailGallery_ImagesContainer .react-photo-gallery--gallery div {
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .DetailGallery_photo_container {
        margin: 10px 0;
    }
    .DetailGallery_photo_container .galleryphoto_DetailGallery{
        width: 100%;
    
    }
   
    .DetailGallery_ImagesContainer .react-photo-gallery--gallery div img {
        width: 100%;
    }

}