.Contact_Hos_topdiv {
    margin-bottom: 13%;
}

.Contact_Hos_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(60%);
}

.Contact_Hos_topdiv .hosp_Contacthead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Contact_Hos_topdiv .hosp_Contacthead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
    text-shadow: 4px 4px 20px black;
}




.Contact_Hos_topdiv img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    margin-bottom: 0px;
}


.agriLogistic_topdiv {
    text-align: center;
    width: 800px;
    margin: auto;
    padding: 50px 0;
}

.agriLogistic_topdiv h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}

.p5tagdiv {
    padding: 50px;
    background-color: #f1eefa;
}

.contactdiv {
    display: flex;
    margin: 20px 0;

}

.contactdiv .ngo_icondiv {
    margin-right: 15px;
    display: flex;
    justify-content: center;
}


.contactdiv .ngo_icondiv p {
    background-color: #b0a2f0;
    color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-top: 4px;
}

.contactdiv .ngo_icondiv p .icons {
    padding: 5px;
    width: 30px;
    height: 30px;

}

.contactdiv .ngocontact {
    cursor: pointer;
}

.contactdiv h6 {
    margin: 0;
    font-weight: bold;
    font-size: 18px;
}

.contactdiv p {
    margin: 0;
    font-size: 18px;
    font-family: "Roboto", sans-serif;


}

.formdiv {
    margin-left: 10px;
    padding: 0;
}

.formdiv div input {
    height: 50px;
}


.btnbtnbtn {
    background: linear-gradient(to right, #3d257b, #572d5c);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}

.ggggg {
    margin-right: 20px;
    font-family: "Roboto", sans-serif;

}

.TouchContact {
    background-color: #fff;
    border-radius: 6px;
    padding: 40px;
    margin-right: 40px;
}

.mapframe{
    width: 100%;
    height: 400px;
}


@media screen and (max-width:767px) {
    .Contact_Hos_topdiv img {
        height: 250px;
    }

    .Contact_Hos_topdiv .hosp_Contacthead {
        margin-top: -42%;

    }

    .Contact_Hos_topdiv .hosp_Contacthead h1 {
        font-size: 30px;
    }

    .p5tagdiv {
        padding: 50px 10px;
    }

    .agriLogistic_topdiv {
        width: 100%;
    }

    .agriLogistic_topdiv h1 {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .contactdiv .ngo_icondiv p {
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }

    .contactdiv .ngo_icondiv p .icons {
        padding: 5px;

    }

    .formdiv {
        margin: 5px;
        padding: 0;
    }

    .TouchContact {
        width: 90%;
        margin-right: 0px;
        margin-left: 20px;

    }
    .btnbtnbtn {
        font-size: 18px;
        letter-spacing: 1px;
    }
}
