.internationalTour_slide {
    padding: 10px 5px;
}

.internationalTour_slide a {
    text-decoration: none;
}

.Tour_life_Slider {
    margin: 20px;
    width: 90% !important;
    text-align: justify;
    
    border-radius: 90% 90% 3% 3% !important;
    background-color: brown;
    padding-bottom: 10px;
    color: white;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.Tour_life_Slider img {
    height: 70vh;
    width: 100%;
    object-fit: cover;
    border-radius: 90% 90% 0 0 !important;
    border-top: 10px solid white;
    border-right: 10px solid white;
    border-left: 10px solid white;
}

.Tour_life_Slider h4 {
    margin:0;
    text-align: center;
    padding: 10px 0;
    font-weight: 700;
    font-size: 18px;
    width: auto;
    /* Set the width of the element */
    white-space: nowrap;
    /* Prevent the text from wrapping */
    overflow: hidden;
    /* Hide the overflow text */
    text-overflow: ellipsis;
    /* Display the ellipsis (...) */
}



@media screen and (max-width:767px) {
    .internationalTour_slide {
        padding: 10px 10px;
        width: 100%;
    }

    .internationalTour_slide h1 {
        font-size: 18px;
    }

    .Tour_life_Slider img {
        height: 350px;
        width: 100% !important;
        /* margin-left: 10%; */
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .internationalTour_slide {
        padding: 10px 5px;
    }

    .Tour_life_Slider img {
        height: 250px;
        width: 200px;
        object-fit: contain;
        border-radius: 4px;
    }
}