
#BoxId {
    margin: 0 !important;
}

.custom-img img {
    height: 33rem;
    width: 100%;
    filter: brightness(0.6);
    object-fit: cover;
}

#BoxId .swiper-pagination .swiper-pagination-bullet {
    width: 16px !important;
    height: 6px !important;
    border-radius: 2px !important;
}


.curriculumdiv{
    margin: 0px 0;
    /* background-color: rgb(34, 111, 25); */
    background-image: url('../assest/taniska/pexels-7inch.webp');
    color: white;
    padding: 40px 0;
}

.curriculumdiv .curriculum_cocurricular{
    margin: 40px 10%;

}
.curriculumdiv .curriculum_cocurricular h2{
    font-size: 50px;
    font-weight: bold;
    text-align: start;
}
.curriculumdiv .curriculum_cocurricular p{
    font-size: 30px;
    text-align: start;
}
.curriculum_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.curriculum_alldetail a{
    color: white;
}
.linktag_curri {
    text-decoration: none;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    background-color: #000000;
}

.curriculum_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 6px;
    text-align: center;
}
.curriculum_areadiv div img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 1% 1% 0 0;
    margin: -20px 0 20px 0;

}
.curriculum_areadiv h1{
    font-weight: bold;
    padding-left: 10px;
    color: white;
    font-size: 60px;
    text-align: start;
    margin: 20px 0;

}

.tanishka_culmdiv {
    margin: 0px 0;
    background-color: #ccc3b0;
    color: black;
    padding: 40px 0;
}

.tanishka_culmdiv .tanishka_cocurricular {
    margin: 40px 3%;

}

.tanishka_culmdiv .tanishka_cocurricular h2 {
    font-size: 40px;
    text-align: start;
}

.tanishka_culmdiv .tanishka_cocurricular p {
    font-size: 17px;
    letter-spacing: 1px;
    text-align: start;
}

.tanishka_culmdiv .tanishka_cocurricular button {
    background: linear-gradient(to right, #5f6cff, #97fe7b);
    color: white;
    text-transform: capitalize;
    font-size: 20px;
    letter-spacing: 2px;
    padding: 8px 60px;
    margin: 20px 0;
    border-radius: 6px;
    border: none;
}

.tanishka_clients {
    text-align: center;
    width: 90%;
    margin: auto;
    padding: 50px 0;
}

.tanishka_clients h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 40px;
}


.tanishkaProjects_topimg {
    border: 1px solid lightgray;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    width: 90% !important;
    padding: 20px;
}

.tanishkaProjects_topimg img {
    height: 120px;
    width: 120px;
    object-fit: cover;
    margin: auto;
    align-items: center;
    border: 1px solid rgb(142, 139, 139);
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 4px;
    border-radius: 50%;
}

.tanishkaProjects_topimg p {
    font-weight: bold;
    margin-top: 20px;
}


.offer_maindiv {
    margin-top: -36%;
    margin-bottom: 11%;
}

.specialoffer_experience img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(20%);
}


.specialoffer_experience .specialoffer_detail {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    filter: brightness(100%);

}

.specialoffer_experience .specialoffer_detail h1 {
    font-weight: bold;
    font-size: 40px;
    letter-spacing: 2px;
    padding: 0 15%;
}

.specialoffer_experience .specialoffer_detail p {
    font-size: 15px;
    letter-spacing: 2px;
    margin-top: 20px;
    text-align: center;
    padding: 0 15%;

}

.specialoffer_experience .specialoffer_detail button {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 30px;
    margin: 30px auto;
    background-color: aliceblue;
    color: black;
    width: 300px;
}

.specialoffer_experience .specialoffer_detail button:hover {
    background-color: aliceblue;
    color: black;
}

.specialoffer_experience .specialoffer_detail .startingprice{
    background-color: #2e5d21;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    color: antiquewhite;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed white;
}
.specialoffer_experience .specialoffer_detail .startingprice p{
    font-size: 30px;
    font-weight: 500;
    margin: 5px 0;
}
.specialoffer_experience .specialoffer_detail .startingprice h3{
    font-size: 40px;
    font-weight: bold;
    margin: 5px 0;
}
.Tanishka_weworkIn {
    margin: 0px 0;
    background-color: rgb(50, 72, 64);
    color: white;
    padding: 40px 0;
}

.why_tanishkadiv {
    width: 90%;
    margin: auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.why_tanishkadiv h1 {
    font-size: 40px;
    font-weight: 800;
    margin: 0;

}
.why_tanishkadiv .btnn {
    margin: 0;
    background-color: #ff5f5f;
    color: white;
    text-transform: capitalize;
    padding: 8px 20px;
    font-size: 20px;
    border-radius: 6px;
    border: none;
}


.whytanis_alldetail {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.whytanis_alldetail a {
    color: white;
}
.linktag_whytanis {
    text-decoration: none;
    border: 1px solid lightgray;
    border-radius: 4px;
    padding: 0;
    background-color: #242424;
    width: 410px;
}

.whytanis_areadiv {
    width: 100%;
    margin: 20px 0px;
    border-radius: 6px;
    text-align: center;
}

.whytanis_areadiv div img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;

}

.whytanis_areadiv div h6 {
    font-weight: 600;
    padding: 0 20px;
    color: white;
    font-size: 24px;
    margin: 20px 0;
    text-align: start;

}

.whytanis_areadiv div p {
    padding: 0 20px;
    color: white;
    text-align: justify;
    font-size: 17px;

}



/* testimonial */
#tanishka_testimonial {
    background-color: #f3e6f9;
    padding-bottom: 30px;
}

.testi_h2 {
    font-size: 40px;
    letter-spacing: 1px;
    font-weight: bold;
    padding: 60px 0 60px 30px;
    color: #56594d;
    margin: 0;
    text-align: center;
}

.tanishka_testimonial_container {
    width: 100%;
    padding-bottom: 1rem;
}

.testimonial_div_tanishka {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
}

/* .tanishka_testimonial_container .swiper-wrapper {
    width: 100%;
}

.tanishka_testimonial_container .swiper-wrapper .swiper-slide {
    width: 100% !important;
} */

/* .tanis_div_avatar {
    width: 160px;
    height: 160px;
    overflow: hidden;
    border-radius: 50%;
    border: 0.4rem solid #8acd93;
} */

.tanishka_testimonial_slide {
    background: white;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
    height: 500px;
    margin: 30px 0;
    width: 400px !important;
}

.tanis_name {
    font-size: 20px;
    font-weight: 700;
    margin-top: 50px;
    font-style: italic;
}

.tanis_review {
    color: #000000;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-style: italic;

}

.tanis_message {
    color: #000000;
    font-size: 16px;
    font-style: italic;
    align-items: center;
    text-align: justify;
}


/* end testimonial */

@media screen and (max-width:767px) {
    #BoxId {
        width: 100%;
    }
    .custom-img img {
        width: 100%;
        height: 15rem;
        margin: 0;
    }

    #BoxId .swiper-slide {
        margin: 0 !important;
    }
    .curriculumdiv .curriculum_cocurricular h2{
        font-size: 40px;
    }
    .tanishka_culmdiv .tanishka_cocurricular h2 {
        font-size: 40px;
    }

    .specialoffer_experience img {
        height: 160vh;
    }

    .offer_maindiv {
        margin-top: -185%;
        margin-bottom: 24%;
    }

    .specialoffer_experience .specialoffer_detail h1 {
        font-size: 22px;
        padding: 0 15%;
    }

    .specialoffer_experience .specialoffer_detail p {
        font-weight: 400;
        font-size: 16px;
        margin-top: 10px;
        padding: 0 15%;

    }
    .why_tanishkadiv {
        width: 100%;
        padding: 20px;
    }

    .why_tanishkadiv h1 {
        font-size: 26px;
    }
    .linktag_whytanis {
        width: 90%;
    }

    .tanishka_testimonial_slide {
        background: white;
        padding: 2rem 1rem;
        border-radius: 1rem;
        user-select: none;
        height: auto !important;
        margin: 30px 0;
    }

    /* .tanis_div_avatar {
        width: 99%;
        height: 210px;
        aspect-ratio: 1/1;
        overflow: hidden;
        border-radius: 2%;
        margin:1px;
        border: 0.4rem solid #8acd93;
    } */

}