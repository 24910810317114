
.hos_International_topdiv {
    margin-bottom: 13%;
}

.hos_International_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.hos_International_topdiv .hosp_Internationalhead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.hos_International_topdiv .hosp_Internationalhead h1 {
    font-weight: bold;
    font-size: 70px;
    letter-spacing: 2px;
}



@media screen and (max-width:767px) {
    .hos_International_topdiv img {
        height: 250px;
    }

    .hos_International_topdiv .hosp_Internationalhead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .hos_International_topdiv .hosp_Internationalhead h1 {
        font-size: 30px;
    }

}