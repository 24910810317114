.Hosp_locationdetail_topdiv {
    margin-bottom: 13%;
}

.Hosp_locationdetail_topdiv img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    margin-bottom: 0px;
    filter: brightness(40%);
}

.Hosp_locationdetail_topdiv .hosp_locationdetailhead {
    color: white;
    margin-top: -20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: brightness(100%);

}

.Hosp_locationdetail_topdiv .hosp_locationdetailhead h1 {
    font-weight: bold;
    font-size: 70px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.celebrity-filtration-div {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    padding-bottom: 20px;
}

.celebrity-filtration-div::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), url('../assest/taniska/audience-1850.webp');
    background-size: contain;
    background-repeat: repeat;
    opacity: 0.2;
    z-index: -44;
}

.leftsidediv {
    backdrop-filter: blur(15px);
    /* border: 1px solid rgb(149, 40, 40); */
    background-color: #f0f3f5;
    border-radius: 4px;
    max-width: 300px;
    margin-top: 30px;
    margin-left: 10px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

}

.filterdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #babdbe;
    padding: 10px 20px;
    border-radius: 4px 4px 0 0;

}

.filterdiv h5 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.filterdiv h6 {
    font-size: 15px;
    font-weight: bold;
    margin: 0;

}

.filterdiv img {
    width: 20px;
    height: 20px;
}

.city-div {
    font-weight: 500;
    margin: 30px 10px;
}

.city-div h5 {
    margin: 10px 0;
}

.city-div .clickdiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: white;
    padding: 8px;
    cursor: pointer;
}

.city-div h6 {
    margin: 2px 0;
    color: #454545;
    font-weight: bold;
    font-size: 16px;

}

.city-div ul {
    line-height: 25px;
    padding: 10px;
    list-style: none;
    background-color: white;
}

.city-div .category-list {
    /* height: 300px; */
    /* overflow-y: scroll; */
}

.city-div .index-list {
    height: 300px;
    overflow-y: scroll;
}

.city-div ul li {
    font-size: 16px;
    text-transform: capitalize;
}

.zone-list,
.price-list,
.gender-list,
.index-list,
.country-list,
.grade-list,
.category-list {
    border: 1px solid lightgray;
    border-radius: 0 0 4px 4px;
    padding: 5px 10px;
    box-shadow: 0 0 6px white;

}

.rightsidediv {
    width: 850px;
    max-width: 950px;
    margin-left: 0px;
}

.card-box {
    width: 250px;
    border-radius: 6px;
    margin: 20px 20px;
}

.card-box .card-img-div1 {
    background-image: url('https://www.influglue.com/files/profileimages/9d297f2de472f062ffc0c65bbe5e0c0a.jpg');
}

.card-box .card-img-div1 {
    height: 300px;
    width: 250px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px 6px 0 0;

}

.card-box .card-img-div1 img {
    width: 30px;
    margin: 10px 0 0 10px;
}

.card-box .card-img-div1 p span {
    color: rgb(221, 26, 26) !important;
}

.card-box .card-content-div {
    text-align: center;
    background-color: rgb(39, 102, 57);
    color: white;
    border-radius: 0 0 6px 6px;
    /* height: 300px; */
}


.card-box .card-content-div h6 {
    font-weight: bold;
    padding: 10px 0 3px 0;
    margin-bottom: 3px;
}

.card-box .card-content-div p {
    padding: 5px 5px 20px 0;
}

.card-box .card-content-div div button {
    margin: 5px;
    border-radius: 20px;
    color: white;
    border: 1px solid white;
}

.card-box .card-content-div div ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    justify-content: center;
    padding: 30px 0;
}

.card-box .card-content-div div ul li p {
    margin-bottom: 2px;
    font-weight: bold;
}

.card-box .card-content-div div ul li span {
    font-size: 14px;
}

.card-box .card-content-div div ul li {
    border-top: 1px solid grey;
    padding: 10px 15px;
}

.btn-div-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.btn-div-bottom button {
    background-color: green;
    color: white;
    border-radius: 30px;
    padding: 12px 50px;
    border: 1px solid green;
    font-size: large;
    font-weight: 600;

}

.btn-div-bottom button:hover {
    background-color: white;
    color: green;
    border: 1px solid green;
    border-radius: 30px;

}

.heretoserve {
    margin: 30px 20px;
}

.heretoserve h1 {
    font-size: 24px;
    font-weight: bold;
}

.fixedDeparture_package {
    background-color: #ffffff;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    margin-top: 30px;
    padding: 20px 20px 10px 20px;
    border-radius: 4px;

}

.fixedDeparture_package div img {
    width: 260px;
    height: 155px;
    border-radius: 3px;
    box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);
    /* border: 1px solid #4d3b49; */

}

.detail_full {
    display: flex;
    justify-content: space-between;

}

.detail_full .leftdivname {
    width: 400px;

}

.detail_full .rightdivname {
    width: 200px;
    text-align: right;

}

.detail_full .rightdivname p {
    margin: 3px 0;
    font-size: 14px;
}

.detail_full .rightdivname h2 {
    font-size: 24px;
    font-weight: bold;
}

.detail_full .rightdivname h2 span {
    font-size: 17px;
}

.detail_full div h3 {
    font-size: 18px;
    font-weight: bold;
}


.detail_full div h5 {
    font-size: 14px;
    font-weight: bold;
}

.detail_full div h4 {
    font-size: 18px;
}



.Includesbotmmdiv {
    display: flex;
    justify-content: space-between;
}

.Includesbotmmdiv ul {
    display: flex;
    list-style: none;
    margin: 10px 0;
    padding: 0;
}

.Includesbotmmdiv ul li {
    text-align: center;
    margin: 20px 20px 0px 20px;
}

.Includesbotmmdiv ul li img {
    width: 30px;
    height: 30px;
}

.Includesbotmmdiv ul li p {
    font-size: 14px;
    margin: 4px 0;
}

.Includesbotmmdiv div {
    display: flex;
    margin: 10px 0;
}


.fixedDeparture_package .butndivbox {
    display: flex;
    float: right;
}

.fixedDeparture_package div .btnn1 {
    border: 3px solid brown;
    text-transform: capitalize;
    background-color: white;
    color: black;
    height: 40px;
    margin: auto;
    padding: 0;

}


.fixedDeparture_package div .btnn1 a {
    text-decoration: none;
    color: black;
    padding: 6px 10px;
}

.fixedDeparture_package div .btnn2 {
    border: 3px solid brown;
    text-transform: capitalize;
    background-color: brown;
    color: white;
    height: 40px;
    margin: auto 10px;


}

.boxbottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.boxbottom .tabsdivbox {
    display: flex;
    flex-direction: column;
    margin: 10px 0;


}

.boxbottom .menutabs {
    border: none;
    text-align: center;
    margin: 20px 10px 0px 10px;
    background-color: unset;
}


.boxbottom .menutabs img {
    width: 30px;
    height: 30px;
}

.boxbottom .menutabs p {
    font-size: 14px;
    margin: 4px 0;
}

.boxbottom .tablePanelBox {
    background-color: aliceblue;
    width: 100% !important;
    padding: 20px;
}

/* .boxbottom .tablePanelBox .card {
    width: 750px;
} */
.boxbottom .tablePanelBox .card .table-responsive{
    width: 100%;
    overflow-x: auto; 
    
}
.boxbottom .tablePanelBox .card .table-responsive .table{
    min-width: 750px;
    width: 100%;
}

.table .css-1ygcj2i-MuiTableCell-root {
    font-weight: bold !important;
}


.detailformdiv {
    margin-left: 10px;
    padding: 0;
}

.detailformdiv div input {
    height: 50px;
}

@media screen and (max-width:767px) {
    .Hosp_locationdetail_topdiv {
        margin-bottom: 35%;
    }

    .Hosp_locationdetail_topdiv img {
        height: 250px;
    }

    .Hosp_locationdetail_topdiv .hosp_locationdetailhead {
        margin-top: -42%;
        padding: 0 20px;
        text-align: center;

    }

    .Hosp_locationdetail_topdiv .hosp_locationdetailhead h1 {
        font-size: 30px;
    }

    .celebrity-filtration-div {
        display: block;
        padding-top: 20px;
    }

    .leftsidediv {
        width: 100% !important;
        max-width: 86%;
        margin-left: 27px;
        padding-left: 0;
        margin-top: 0;
    }

    .filterdiv {
        padding: 10px 5px;
        display: flex;
        align-items: center;
    }

    .filterdiv h5 {
        font-size: 14px;
        font-weight: bold;
    }

    .filterdiv img {
        width: 15px;
        height: 15px;
    }

    .city-div ul {
        padding: 10px 8px;
    }

    .rightsidediv {
        margin: 4px 0px 4px 4px;
        width: 100% !important;
    }

    .fixedDeparture_package {
        padding: 20px 10px;
        margin: 20px;

    }

    .fixedDeparture_package div img {
        width: 100%;

    }


    .detail_full {
        display: block;
        padding: 20px 10px;

    }

    .detail_full .leftdivname {
        width: 100%;

    }

    .detail_full .rightdivname {
        width: 100%;

    }

    .detail_full div h4 {
        font-size: 16px;
    }

    .detailformdiv {
        margin-left: 10px;
        padding: 10px 0px 0 10px;
    }



    .boxbottom {
        display: block;
        margin-left: 45%;
    }


    .boxbottom ul li {
        text-align: center;
        margin: 20px auto 0px auto;
    }

    .boxbottom ul li img {
        width: 20px;
        height: 20px;
    }

    .boxbottom ul li p {
        font-size: 12px;
        margin: 4px 0;
    }

    .boxbottom div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* margin: 10px 0; */
    }

    .boxbottom .menutabs {
        margin: 20px 15px 0px 15px;
        padding: 30px 0 0 0;
    }

    .boxbottom div .btnn1 {
        border: 3px solid brown;
        text-transform: capitalize;
        background-color: white;
        color: black;
        height: 40px;
        margin-left: 10px;
        padding: 0;
    }

    .boxbottom div .btnn1 a {
        text-decoration: none;
        color: black;
        padding: 6px 10px;
    }

    .boxbottom div .btnn2 {
        border: 3px solid brown;
        text-transform: capitalize;
        background-color: brown;
        color: white;
        height: 40px;
        margin: auto 10px;

    }


    .rightsidediv,
    .card-box {
        margin: 10px 0px 10px 3px;
    }

    .card-box .card-img-div1 {
        width: 100%;
    }

    .card-box .card-content-div {
        width: 100%;
    }

    .btn-div-bottom {
        margin: 20px 0 40px 0;
        height: unset;

    }

    .btn-div-bottom button {
        padding: 10px 20px;
        font-size: medium;
        font-weight: 500;
    }

    .boxbottom .tablePanelBox {
        width: 350px !important;
        overflow-x: auto;
        padding: 0px;
        background-color: unset;

    }

    .boxbottom .tablePanelBox .card {
        width: 350px !important;
        overflow-x: auto;
        margin-left: 30px;
        background-color: unset;
        justify-content: flex-start;
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .leftsidediv {
        max-width: 400px;
        width: 300px !important;
        margin-top: 20px;
        margin-left: 3%;
    }

    .city-div {
        font-weight: 500;


        margin: 30px 10px;
    }

    .city-div h5 {
        margin: 10px 0;
    }

    .city-div .clickdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid lightgray;
        border-radius: 4px;
        background-color: white;
        padding: 8px;
        cursor: pointer;
    }

    .city-div h6 {
        margin: 2px 0;
        color: #454545;
        font-weight: bold;
        font-size: 16px;

    }

    .city-div ul {
        line-height: 25px;
        padding: 10px;
    }

    .city-div .category-list {
        height: 300px;
        overflow-y: scroll;
    }

    .city-div .index-list {
        height: 300px;
        overflow-y: scroll;
    }

    .city-div ul li {
        font-size: 14px;
        text-transform: uppercase;
    }

    .zone-list,
    .price-list,
    .gender-list,
    .index-list,
    .country-list,
    .grade-list,
    .category-list {
        border: 1px solid lightgray;
        border-radius: 0 0 4px 4px;
        padding: 5px 10px;
    }

    .rightsidediv {
        width: 600px !important;
        margin-left: 10px;
    }

    .card-box {
        width: 350px;
        border-radius: 6px;
        margin: 20px 20px;
    }

    .card-box .card-img-div1 {
        background-image: url('https://www.influglue.com/files/profileimages/9d297f2de472f062ffc0c65bbe5e0c0a.jpg');
    }


    .card-box .card-img-div1 {
        height: 300px;
        width: 350px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-radius: 6px 6px 0 0;

    }

    .card-box .card-img-div1 img {
        width: 30px;
        margin: 10px 0 0 10px;
    }

    .card-box .card-img-div1 p span {
        color: rgb(221, 26, 26) !important;
    }

    .card-box .card-content-div {
        text-align: center;
        background-color: rgb(39, 102, 57);
        color: white;
        border-radius: 0 0 6px 6px;
        /* height: 300px; */
    }

    .card-box .card-content-div h6 {
        font-weight: bold;
        padding: 10px 0 3px 0;
        margin-bottom: 3px;
    }

    .card-box .card-content-div div button {
        margin: 5px;
        border-radius: 20px;
        color: white;
        border: 1px solid white;
    }

    .card-box .card-content-div div ul {
        list-style: none;
        display: flex;
        padding-left: 0;
        justify-content: center;
        padding: 30px 0;
    }

    .card-box .card-content-div div ul li p {
        margin-bottom: 2px;
        font-weight: bold;
    }

    .card-box .card-content-div div ul li span {
        font-size: 14px;
    }

    .card-box .card-content-div div ul li {
        border-top: 1px solid grey;
        padding: 10px 15px;
    }
}